import React from "react";
import Seo from "../components/Seo/Seo";
import { Section, Text, MainTitle, Title } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import hero from './../assets/image/jpeg/wayleaves-landlords-header.jpg';
import nextSteps from './../assets/image/jpeg/wayleaves-landlords-next-steps.jpg';
import tenantsBackground from "./../assets/image/jpeg/tenants-background.jpg";
import wayleave from './../assets/image/png/what-is-a-wayleave.png';
import { Container, Row, Col } from "react-bootstrap";
import tenants1 from './../assets/image/svg/value-of-property.svg';
import tenants2 from './../assets/image/svg/tennants-are-happier.svg';
import tenants3 from './../assets/image/svg/attractive-propert.svg';

const WayleavesForLandlords = (props) => {

    const HeroImg = styled.img`
        filter: brightness(0.8);
    `;

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 120px;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 120px;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 120px;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const PinkTitle = styled(Title)`
        color: #db118b;
        margin-top: 100px;
        text-align: left;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
    `;

    const NarrowWrapper = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 0 100px 0;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
        padding-left: 15px;
        @media (min-width: 576px) { 
            padding-left: calc((100vw - 525px) / 2);
            padding-right: calc((100vw - 525px) / 2);
            p {
                font-size: 28px;
            }
        }
        @media (min-width: 768px) { 
            padding-left: calc((100vw - 705px) / 2);
            padding-right: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            padding-left: calc((100vw - 945px) / 2);
            padding-right: calc((100vw - 945px) / 2);
        }

        @media (max-width: 991px) { 
            padding-top: 0;
            padding-bottom: 40px;
        }

        @media (min-width: 1200px) {
            padding-left: 0;
            padding-right: calc((100vw - 1153px) / 2);
        }
    `;

    const SmallParagraph = styled(Text)`
        font-size: 18px;
        line-height: 30px;
    `;

    const BgSection = styled(Section)`
        background: url(${tenantsBackground});
        background-size: cover;
        background-repear: no-repeat;
    `;

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
        }
        &.box {
            border-radius: 50px;
            padding: 50px;
        }
    `;


    return (
        <div>
            <Seo title='Netomnia - Wayleaves for landlords' description='Netomnia - Wayleaves for landlords'/>
            <div className="hero-wayleaves">
                <HeroImg src={hero} className="w-100 mt-10" />
                <AbsoluteTitle>Wayleave<br />information<br />for landlords</AbsoluteTitle>
            </div>
            <PageWrapper footerDark>
                <Row className="justify-content-center text-center dark-bg">
                    <Col xl="5" className="flex-end">
                        <img src={wayleave} className="w-100 mt-5 first-img"/>
                    </Col>
                    <Col xl="1"></Col>
                    <Col xl="6">
                        <NarrowWrapper>
                            <PinkTitle>What is a wayleave?</PinkTitle>
                            <Text>
                                A wayleave is an agreement between the
                                freeholder and a supplier, in this case
                                Netomnia. This gives the supplier the right
                                to access the property and carry out pre
                                agreed works to install the supplier's
                                infrastructure. 
                            </Text>
                            <Text className="mt-3">
                                The Wayleave also allows the
                                supplier to maintain the infrastructure
                                under prior notice to the freeholder.
                            </Text>
                        </NarrowWrapper>
                    </Col>
                </Row>
                <Section className="white-bg">
                    <Container>
                        <MainTitle className="text-center pink-color">Benefits of full fibre</MainTitle>
                        <Row className="justify-content-center text-center">
                            <Col lg="4" className="mt-5">
                                <img src={tenants1} alt="Netomnia Multiple devices" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Multiple devices can be used
                                        simultaneously even during peak hours.
                                    </SmallParagraph>
                                </div>  
                            </Col>
                            <Col lg="4" className="mt-5">
                                <img src={tenants2} alt="Netomnia Full fibre" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Full fibre provides excellent download
                                        and upload speeds meaning working from
                                        home has never been easier as well as a
                                        host of other benefits.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="4" className="mt-5">
                                <img src={tenants3} alt="Netomnia ISP partner" height={100}/>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Our ISP partner YouFibre offers better
                                        value per Mbps in comparison to some
                                        of the household names. They offer up
                                        to 8,000Mbps for residential packages.
                                    </SmallParagraph>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <BgSection>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <Title variant="news">Involved costs?</Title>
                                <SmallParagraph className="mb-4">
                                    There are absolutely no costs to you for the signing of 
                                    a wayleave or the installation of full fibre at your property.
                                </SmallParagraph>
                                <hr className="mb-4" />
                                <Title variant="news">Process of installation</Title>
                                <SmallParagraph>
                                    The installation process involves drilling a tiny hole in the
                                    exterior of building to feed the fibre optic cable into the
                                    property.
                                </SmallParagraph>
                                <SmallParagraph>
                                    From here, the cable is plugged into a device called the
                                    ONT which will enable the tenant's router to connect to
                                    the internet.
                                </SmallParagraph>
                                <SmallParagraph className="mb-5">
                                    It's a very straightforward process that usually takes no
                                    longer than a couple of hours.<br />
                                    If an adult over the age of 18 is present during the
                                    installation, we can upgrade your property's connection.
                                </SmallParagraph>
                            </Col>
                            <Col lg="6">
                                <GradientSection className="box">
                                    <Title variant="news">Free survey</Title>
                                    <SmallParagraph>
                                        As a freeholder or a managing agent of rented,
                                        leased, or let properties that are within our build
                                        plans, you can get in touch with us to arrange a
                                        free-of-charge non-intrusive survey of the building.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        The survey is to ensure your property is safe for
                                        network installation work. While our experts are
                                        on-site, they will require access to all common
                                        areas outside and inside the building so they can
                                        design a unique pack to show the exact routes of
                                        the work they will need to do.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        With the property’s integrity being our top priority,
                                        we make sure our work is carried out
                                        professionally at all times.
                                    </SmallParagraph>
                                </GradientSection>
                            </Col>
                        </Row>
                    </Container>
                </BgSection>
                <GradientSection>
                    <Container>
                        <Row>
                            <Col lg="6" className="centered">
                                <img src={nextSteps} className="w-100 border-radius-10 mb-4"/>
                            </Col>
                            <Col lg="6">
                                <Title variant="news">What are the <br />next steps</Title>
                                <SmallParagraph>
                                    We are here to help accommodate a
                                    broadband connection that can keep up
                                    with today's digital demand to as many
                                    premises as possible.
                                </SmallParagraph>
                                <SmallParagraph className="mb-4">
                                    If you are wanting a full fibre connection,
                                    and you are wanting to have a full fibre
                                    connection installed, please do reach out
                                    to our expert team at<br />
                                    <b><a href="mailto:wayleaves@netomnia.com">wayleaves@netomnia.com.</a></b>
                                </SmallParagraph>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
            </PageWrapper>
        </div>
    )
}

export default WayleavesForLandlords;
